import { type ReactNode } from 'react';

import { default as ReactModal } from 'react-modal';
import styled from 'styled-components';

import { CDS_TEST_ID_DIALOG_CLOSE_BUTTON } from '../../testIDs';
import { XIconButton } from '../Button/XIconButton';

interface StyledReactModalProps {
  isHeaderDisabled: boolean;
  isBodyPaddingDisabled: boolean;
}
const StyledReactModal = styled(ReactModal)<StyledReactModalProps>`
  --close-button-height: ${({ isHeaderDisabled }) => (isHeaderDisabled ? '0' : '25px')};
  --header-padding: ${({ theme }) => theme.space.s5};
  --body-padding: ${({ isBodyPaddingDisabled, theme }) => (isBodyPaddingDisabled ? '0' : theme.space.s5)};
  --border-radius: ${({ theme }) => theme.radii.r100};
  overscroll-behavior: contain;
`;

const Overlay = styled.div`
  background-color: rgba(28, 53, 90, 0.8) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overscroll-behavior-block: contain;
`;

const Content = styled.div`
  background-color: white;
  border-radius: var(--border-radius);
  overflow: auto;
  overscroll-behavior-block: contain;
`;

const Header = styled.div`
  background-color: white;
  justify-content: space-between;
  padding: var(--header-padding);
`;

const Body = styled.div`
  padding: var(--body-padding);
  background-color: white;
  max-height: calc(100vh - var(--close-button-height) - var(--header-padding) * 2);
  color: ${({ theme }) => theme.colors.capsuleBlue50};
`;

const DialogCloseButton = styled(XIconButton)`
  color: ${({ theme }) => theme.colors.capsuleGray50};
  height: var(--close-button-height);
  margin-left: auto;
  cursor: pointer;
`;

interface CloseButtonProps {
  setIsOpen: (isOpen: boolean) => void;
}

export const CloseButton = ({ setIsOpen }: CloseButtonProps) => {
  return (
    <DialogCloseButton
      data-testid={CDS_TEST_ID_DIALOG_CLOSE_BUTTON}
      helperText="close"
      onClick={() => setIsOpen(false)}
    />
  );
};

export interface ModalProps {
  /**
   * The boolean `state` to open or close the modal.
   */
  isOpen: boolean;
  /**
   * Function to set the `isOpen` state.
   */
  setIsOpen: (isOpen: boolean) => void;
  /**
   * Content of the modal.
   */
  children: ReactNode;
  /**
   * Set `true` to hide the `header` including the close icon at the top.
   */
  isHeaderDisabled?: boolean;
  /**
   * Use to render fullscreen content.
   */
  isBodyPaddingDisabled?: boolean;
}

export const Modal = ({
  isOpen,
  setIsOpen,
  children,
  isHeaderDisabled = false,
  isBodyPaddingDisabled = false,
}: ModalProps) => {
  return (
    <>
      <StyledReactModal
        isHeaderDisabled={isHeaderDisabled}
        isBodyPaddingDisabled={isBodyPaddingDisabled}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        overlayElement={(props, contentElement) => <Overlay {...props}>{contentElement}</Overlay>}
        contentElement={(props, contentElement) => <Content {...props}>{contentElement}</Content>}
      >
        {!isHeaderDisabled && (
          <Header>
            <CloseButton setIsOpen={setIsOpen} />
          </Header>
        )}
        <Body>{children}</Body>
      </StyledReactModal>
    </>
  );
};
