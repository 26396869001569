import type { ReactNode, TouchEventHandler } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

import { invariant } from '../../utils';
import { UnstyledButton } from '../Button/UnstyledButton';
import { ScreenReaderOnly } from '../ScreenReaderOnly/ScreenReaderOnly';

const Circle = styled.span<{ isActive?: boolean }>`
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.capsuleBlue30};
    }
  }

  position: relative;
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.capsuleBlue50 : theme.colors.capsuleGray10)};
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
`;

const UnstyledOrderedList = styled.ol`
  list-style: none;
  margin: 0;
  width: 100%;
`;

const PaginationArrowContainer = styled.li`
  ${({ theme }) => theme.mediaQueries.smallDown} {
    display: none;
  }
`;

const VisibleCarouselItemList = styled(UnstyledOrderedList)<{
  hasSlidePagination: boolean;
  itemGapSize: 'sm' | 'md' | 'lg';
}>`
  --carouselItemGap: ${({ theme, itemGapSize }) => {
    if (itemGapSize === 'md') return theme.space.s5;
    if (itemGapSize === 'lg') return theme.space.s7;
    else return theme.space.s3;
  }};
  --carouselGutter: ${({ theme }) => theme.space.s5};

  overflow-x: ${({ hasSlidePagination }) => (hasSlidePagination ? 'unset' : 'scroll')};

  height: 100%;
  display: flex;
  object-fit: contain;
  gap: var(--carouselItemGap);
  padding: ${({ theme }) => theme.space.s4};
  padding-top: 0;

  ${({ theme }) => theme.mediaQueries.medium} {
    padding: ${({ theme }) => theme.space.s4};
    margin: ${({ hasSlidePagination }) =>
      hasSlidePagination ? '0 calc(var(--carouselGutter) + var(--carouselItemGap))' : '0'};
    padding: 0;
    width: ${({ hasSlidePagination }) =>
      hasSlidePagination ? 'calc(100 % - (var(--carouselGutter) * 2) - (var(--carouselItemGap) * 2))' : '100%'};
  }
`;

const ChangeCarouselPageButton = ({ children, onClick }: { children?: ReactNode | undefined; onClick: () => void }) => (
  <UnstyledButton
    type="button"
    onClick={onClick}
    css={css`
      background-color: inherit;
      border-radius: ${({ theme }) => theme.radii.r100};
      height: 100%;
      &:focus-visible {
        outline: 1px solid ${({ theme }) => theme.colors.capsuleBlue50};
      }
    `}
  >
    {children}
  </UnstyledButton>
);

export type ChangeCarouselPageButtonType = typeof ChangeCarouselPageButton;

const StyledSVG = styled.svg.attrs({
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  xmlns: 'http://www.w3.org/2000/svg',
  'aria-hidden': true,
})`
  display: block;
  fill: currentcolor;
`;

const LeftChevron = () => (
  <StyledSVG>
    <path fill="#0F3C6C" d="M14.7 4.3a1 1 0 1 1 1.42 1.4L9.82 12l6.3 6.3a1 1 0 0 1-1.41 1.4L7 12l7.7-7.7Z" />
  </StyledSVG>
);

const RightChevron = () => (
  <StyledSVG
    css={css`
      transform: rotate(180deg);
    `}
  >
    <path fill="#0F3C6C" d="M14.7 4.3a1 1 0 1 1 1.42 1.4L9.82 12l6.3 6.3a1 1 0 0 1-1.41 1.4L7 12l7.7-7.7Z" />
  </StyledSVG>
);

export interface CarouselProps {
  id: string;
  carouselItems: JSX.Element[];
  pageSize?: number;
  itemGapSize?: 'sm' | 'md' | 'lg';
  isMobileModeDisabled?: boolean;
  isPaginationCircleDisabled?: boolean;
  onNavigate?: (direction: 'left' | 'right' | 'dot' | 'scroll' | 'index') => void;
}

export interface CarouselRef {
  goRight: () => void;
  goLeft: () => void;
  goToIndex: (index: number) => void;
}

export const Carousel = forwardRef<CarouselRef, CarouselProps>(
  (
    {
      carouselItems,
      onNavigate,
      id,
      pageSize = 3,
      itemGapSize = 'sm',
      isMobileModeDisabled = false,
      isPaginationCircleDisabled = false,
    }: CarouselProps,
    ref
  ) => {
    invariant(
      carouselItems.length < 100,
      `Carousel is not prepared for this many items. The pagination dots will overflow and warp. Please update the component and remove the invariant.`
    );

    const { inView: isInView, ref: intersectionObserverRef } = useInView({
      threshold: 0.1,
      triggerOnce: true,
      rootMargin: '100% 0% 100% 0%',
    });

    useEffect(() => {
      if (isInView) onNavigate?.('scroll');
    }, [isInView, onNavigate]);

    const [circularArray, setCircularArray] = useState(carouselItems);

    // Re-initialize state if props change.
    // HACK: There are still edge cases here.
    useEffect(() => {
      if (carouselItems.length !== circularArray.length) {
        setCircularArray(carouselItems);
      }
    }, [carouselItems, circularArray.length]);

    // it is always the first three, the array is circular and rotates from navigation actions
    const activeSlideCarouselItems = circularArray.slice(0, pageSize);

    const carouselLength = carouselItems.length;
    const hasSlidePagination = carouselLength > pageSize;

    // Compute offset from circularArray
    const currentOffset = carouselItems.findIndex((_, index) => carouselItems[index]?.key === circularArray[0]?.key);

    const itemsInLastPage = (): number => carouselLength % pageSize || pageSize; // if 0, it's a "complete" page

    const calculateTargetOffset = (direction: 'left' | 'right'): number => {
      const lastPageIndex = carouselLength - itemsInLastPage();

      if (direction === 'left') {
        return currentOffset === 0 ? lastPageIndex : (currentOffset - pageSize + carouselLength) % carouselLength;
      } else {
        // Direction is right
        return currentOffset === lastPageIndex ? 0 : (currentOffset + pageSize) % carouselLength;
      }
    };

    const goLeft = () => {
      const targetOffset = calculateTargetOffset('left');
      setCircularArray(prev => rotateArray(prev, targetOffset));
      onNavigate?.('left');
    };

    const goRight = () => {
      const targetOffset = calculateTargetOffset('right');
      setCircularArray(prev => rotateArray(prev, targetOffset));
      onNavigate?.('right');
    };

    const goToIndex = (index: number) => {
      const targetOffset = index;
      setCircularArray(prev => rotateArray(prev, targetOffset));
    };

    const rotateArray = <T,>(someArray: T[], targetOffset: number): T[] => {
      const totalLength = someArray.length;
      const shiftAmount = (targetOffset - currentOffset + totalLength) % totalLength;
      return [...someArray.slice(shiftAmount), ...someArray.slice(0, shiftAmount)];
    };

    const [touchStart, setTouchStart] = useState<number>();
    const [touchEnd, setTouchEnd] = useState<number>();

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart: TouchEventHandler<HTMLOListElement> = e => {
      setTouchEnd(undefined); // otherwise the swipe is fired even with usual touch events
      setTouchStart(e.targetTouches[0]?.clientX);
    };

    const onTouchMove: TouchEventHandler<HTMLOListElement> = e => {
      setTouchEnd(e.targetTouches[0]?.clientX);
    };

    const onTouchEnd: TouchEventHandler<HTMLOListElement> = () => {
      if (!touchStart || !touchEnd) return;
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe) {
        goRight();
      } else if (isRightSwipe) {
        goLeft();
      }
    };

    useImperativeHandle(ref, () => ({
      goLeft,
      goRight,
      goToIndex,
    }));

    return (
      <>
        {/* Mobile */}
        <div
          css={css`
            ${({ theme }) => theme.mediaQueries.medium} {
              display: none;
            }
            ${isMobileModeDisabled && `display: none;`};
            position: relative;
            width: 100%;
            display: flex;
            overflow-x: auto; /* Enable horizontal scrolling */
            scroll-snap-type: x mandatory; /* Optional: Snap scrolling */
            gap: 10px; /* Space between cards */
          `}
        >
          <VisibleCarouselItemList
            hasSlidePagination={false}
            itemGapSize={itemGapSize}
            css={css`
              --gutter: 20px;
              display: grid;
              ${isMobileModeDisabled && `display: none;`};

              grid-gap: calc(var(--gutter) / 2);
              grid-template-rows: minmax(150px, 1fr);
              grid-auto-flow: column;
              grid-auto-columns: calc(50% - var(--gutter) * 2);
              overflow-x: scroll;
              scroll-snap-type: x proximity;
              ${({ theme }) => theme.mediaQueries.mediumDown} {
                /* 45% always shows an item peeking in common tablet sizes if there's more */
                grid-auto-columns: calc(45% - var(--gutter) * 2);
                position: relative;
              }
              ${({ theme }) => theme.mediaQueries.smallDown} {
                /* 55% always shows an item peeking in common phone sizes if there's more */
                grid-auto-columns: calc(50% - var(--gutter) * 2);
                position: relative;
              }
            `}
          >
            {carouselItems.map((content, carouselItemIndex) => {
              const isScrollMarker = carouselItemIndex === 3;

              // we apply the intersection-observer ref to the 4th item so we can confirm scrolling has occurred
              const ref = isScrollMarker ? intersectionObserverRef : undefined;

              return (
                <li
                  key={`${id}_${carouselItemIndex}_mobile`}
                  ref={ref}
                  css={css`
                    position: relative;
                  `}
                >
                  {content}
                </li>
              );
            })}
          </VisibleCarouselItemList>
        </div>

        {/* Non-Mobile */}
        <div
          css={css`
            overflow: hidden;
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            ${({ theme }) => theme.mediaQueries.mediumDown} {
              display: none;
              ${isMobileModeDisabled && `display: block;`};
            }
          `}
        >
          <VisibleCarouselItemList
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            aria-live="polite"
            hasSlidePagination={hasSlidePagination}
            itemGapSize={itemGapSize}
          >
            {activeSlideCarouselItems.map((content, carouselItemIndex) => (
              <li
                css={css`
                  flex-basis: calc(100%)});
                  display: flex; /* Make <li> a flex container */
                  justify-content: center; /* Center horizontally */
                `}
                key={`${id}_${carouselItemIndex}`}
              >
                {content}
              </li>
            ))}
          </VisibleCarouselItemList>

          {hasSlidePagination && (
            <>
              <UnstyledOrderedList
                css={css`
                  position: absolute;
                  inset: 0;
                  width: 100%;
                  /** -2.5rem is necessary to make the button focus rings match the height of the actual carousel items */
                  height: calc(100% - 2.5rem);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  pointer-events: none;

                  & > li {
                    pointer-events: all;
                    height: 100%;
                  }
                `}
              >
                <PaginationArrowContainer>
                  <ChangeCarouselPageButton onClick={goLeft}>
                    <ScreenReaderOnly>See Previous Carousel Page</ScreenReaderOnly>
                    <LeftChevron />
                  </ChangeCarouselPageButton>
                </PaginationArrowContainer>

                <PaginationArrowContainer>
                  <ChangeCarouselPageButton onClick={goRight}>
                    <ScreenReaderOnly>See Next Carousel Page</ScreenReaderOnly>
                    <RightChevron />
                  </ChangeCarouselPageButton>
                </PaginationArrowContainer>
              </UnstyledOrderedList>

              {!isPaginationCircleDisabled && (
                <UnstyledOrderedList
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: ${({ theme }) => theme.space.s4};
                    width: 100%;
                    ${({ theme }) => theme.mediaQueries.medium} {
                      margin-top: ${({ theme }) => theme.space.s4};
                    }
                  `}
                >
                  {Array.from({ length: Math.ceil(carouselLength / pageSize) }).map((_, index) => {
                    const isActive = currentOffset / pageSize === index;

                    return (
                      <Circle as="li" key={`${id}_${index}`} isActive={isActive}>
                        <UnstyledButton
                          onClick={() => {
                            const targetOffset = index * pageSize;
                            setCircularArray(prev => rotateArray(prev, targetOffset));
                            onNavigate?.('dot');
                          }}
                          type="button"
                          css={css`
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            height: calc(100%);
                            width: calc(100%);
                            background-color: inherit;
                            border-radius: 9999px;
                            &:focus-visible {
                              outline: 1px solid ${({ theme }) => theme.colors.capsuleBlue50};
                              outline-offset: 1px;
                            }
                          `}
                        >
                          <ScreenReaderOnly>Go to slide number {index + 1}</ScreenReaderOnly>
                        </UnstyledButton>
                      </Circle>
                    );
                  })}
                </UnstyledOrderedList>
              )}
            </>
          )}
        </div>
      </>
    );
  }
);
