var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e03bfd73905535ece73b2a5dabf0d5099f93544b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { init } from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import { getDefaultConfig } from './SentryConfig';

/** @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/ */
init({
  integrations: [
    new BrowserTracing({
      tracingOrigins: [/consumer-client-api(\.staging\.internal)?\.capsule\.com/],
      beforeNavigate: context => {
        if (context.name.includes('/[[...') || context.name.includes('/[...')) {
          return {
            ...context,
            name: window.location.pathname,
          };
        } else {
          return context;
        }
      },
    }),
  ],
  ...getDefaultConfig(process.env.NEXT_PUBLIC_CAPSULE_ENV),

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
