import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import styled, { css, createGlobalStyle } from 'styled-components';
import { Navigation, Pagination, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { Swiper as SwiperType } from 'swiper';
import type { SwiperOptions } from 'swiper/types/swiper-options';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export interface SimpleSliderProps {
  /**
   * List of `Element`, such as `<div>`, `<img>`, and other elements.
   */
  slides: JSX.Element[];
  /**
   * Responsive breakpoints to change the appearance based on window size.
   * i.e.
   * ```
   * breakpoints={{
   *  600: {
   *    slidesPerView: 3.2,
   *    isNavigationButtonVisible: false,
   *    isPaginationCircleVisible: false,
   *  },
   *  800: {
   *    slidesPerView: 4,
   *    isLooped: true,
   *    isNavigationButtonVisible: true,
   *    isPaginationCircleVisible: true,
   *  },
   * }}
   * ```
   */
  breakpoints?: SimpleSliderBreakpointsProps;
  isLooped?: boolean;
  onNextPageCallback?: (firstItemIndex: number) => void;
  onPrevPageCallback?: (firstItemIndex: number) => void;
  onPageUpdateCallback?: (firstItemIndex: number) => void;
  slidesPerView?: number;
  /**
   * Number of slides to slide at a time.
   */
  slidesPerGroup?: number;
  spaceBetweenSlides?: number;
  isPaginationCircleVisible?: boolean;
  isNavigationButtonVisible?: boolean;
  navigationButtonVariation?: 'chevron' | 'circle';
  slidesOffsetBefore?: number;
  slidesOffsetAfter?: number;
}

interface SimpliSliderBreakpointItemProps
  extends Omit<
    SimpleSliderProps,
    'slides' | 'breakpoints' | 'onNextPageCallback' | 'onPrevPageCallback' | 'onPageUpdateCallback'
  > {}

interface SimpleSliderBreakpointsProps {
  // breakpoint minimum window width in pixels
  [width: number]: SimpliSliderBreakpointItemProps;
}

interface ContainerProps {
  isPaginationCircleVisible?: boolean;
}

const Container = styled.div<ContainerProps>`
  --item-z-index: 1;
  --pagination-circle-top-margin: ${props => (props.isPaginationCircleVisible ? 30 : 0)}px;

  height: 100%;
  position: relative;
`;

const SwiperContainer = styled.div`
  position: relative;
  height: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  margin-bottom: var(--pagination-circle-top-margin);
`;

const StyledSwiper = styled(Swiper)`
  position: relative;
  height: 100%;
  margin: auto;
  z-index: 0;
`;

const StyledSVG = styled.svg.attrs({
  width: 24,
  height: 24,
  viewBox: '0 0 24 24',
  xmlns: 'http://www.w3.org/2000/svg',
  'aria-hidden': true,
})`
  display: block;
  fill: currentcolor;
`;

const LeftNavigationIcon = () => (
  <StyledSVG>
    <path fill="#0F3C6C" d="M14.7 4.3a1 1 0 1 1 1.42 1.4L9.82 12l6.3 6.3a1 1 0 0 1-1.41 1.4L7 12l7.7-7.7Z" />
  </StyledSVG>
);

const RightNavigationIcon = () => (
  <StyledSVG
    css={css`
      transform: rotate(180deg);
    `}
  >
    <path fill="#0F3C6C" d="M14.7 4.3a1 1 0 1 1 1.42 1.4L9.82 12l6.3 6.3a1 1 0 0 1-1.41 1.4L7 12l7.7-7.7Z" />
  </StyledSVG>
);

const NavigationButtonContainer = styled.button`
  position: absolute;
  top: calc(50% - var(--pagination-circle-top-margin));
  z-index: 0;
  width: 20px;
  border: none;
  background: transparent;
`;

const NavigationPrevButtonContainer = styled(NavigationButtonContainer)``;

const NavigationNextButtonContainer = styled(NavigationButtonContainer)`
  right: 0px;
`;

const GlobalStyle = createGlobalStyle`
  .simpleSlider {
    .swiper {
      width: calc(100% - 110px) !important;
    }

    .paginationBullet {
      cursor: pointer;
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
      width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
      height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
      display: inline-block;
      border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
      background: var(--swiper-pagination-bullet-inactive-color, #000);
      opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
      position: relative;
      top: 10px;
    }

    .paginationBulletActive {
      background-color: ${({ theme }) => theme.colors.capsuleBlue50} !important;
      opacity: 1 !important;
    }

    .navigationHidden {
      display: none !important;

      ~ .swiper {
        width: 100% !important;
      }
    }

    .navigationButtonCircleContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px !important;
      height: 48px;
      border-radius: 24px;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 0px 1px 0px rgba(110, 120, 129, 0.16), 0px 2px 8px 0px rgba(153, 191, 188, 0.24);
      cursor: pointer;
      * {
        fill: ${({ theme }) => theme.colors.capsuleGreen60};
        color: ${({ theme }) => theme.colors.capsuleGreen60};
      }
    }

    .navigationButtonCircleContainerDisabled {
      background: ${({ theme }) => theme.colors.capsuleGreen10};
      box-shadow: 0px 0px 1px 0px rgba(110, 120, 129, 0.16), 0px 2px 8px 0px rgba(153, 191, 188, 0.24);
      * {
        fill: ${({ theme }) => theme.colors.capsuleGreen50};
        color: ${({ theme }) => theme.colors.capsuleGreen50};
      }
    }


    .navigationButtonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px !important;
      height: 48px;
      border-radius: 24px;
      cursor: pointer;
      * {
        fill: ${({ theme }) => theme.colors.capsuleGreen60};
        color: ${({ theme }) => theme.colors.capsuleGreen60};
      }
    }

    .navigationButtonContainerDisabled {
      * {
        fill: ${({ theme }) => theme.colors.capsuleGreen50};
        color: ${({ theme }) => theme.colors.capsuleGreen50};
      }
    }
  }
`;

export interface SimpleSliderRef {
  navigateToPage: (page: number) => void;
  update: () => void;
}

export const SimpleSlider = forwardRef<SimpleSliderRef, SimpleSliderProps>(
  (
    {
      slides,
      isLooped,
      onPrevPageCallback,
      onNextPageCallback,
      onPageUpdateCallback,
      spaceBetweenSlides,
      slidesPerView,
      slidesPerGroup,
      breakpoints,
      slidesOffsetBefore,
      slidesOffsetAfter,
      navigationButtonVariation,
      isPaginationCircleVisible = true,
      isNavigationButtonVisible = true,
    }: SimpleSliderProps,
    ref
  ) => {
    const sliderRef = useRef<SwiperType | null>(null);

    const prevNav = useRef<HTMLButtonElement>(null);
    const nextNav = useRef<HTMLButtonElement>(null);

    const navButtonStyle =
      navigationButtonVariation === 'circle' ? 'navigationButtonCircleContainer' : 'navigationButtonContainer';

    const navDisabledButtonStyle =
      navigationButtonVariation === 'circle'
        ? 'navigationButtonCircleContainerDisabled'
        : 'navigationButtonContainerDisabled';

    const handlePrevPage = useCallback(() => {
      sliderRef.current?.slidePrev();
      if (onPrevPageCallback && sliderRef.current) {
        onPrevPageCallback(sliderRef.current.realIndex);
      }
    }, [onPrevPageCallback]);

    const handleNextPage = useCallback(() => {
      sliderRef.current?.slideNext();
      if (onNextPageCallback && sliderRef.current) {
        onNextPageCallback(sliderRef.current.realIndex);
      }
    }, [onNextPageCallback]);

    const navigateToPage = useCallback((page: number) => {
      sliderRef.current?.slideTo(page);
    }, []);

    const update = () => {
      sliderRef.current?.update();
    };

    useImperativeHandle(ref, () => ({
      navigateToPage,
      update,
    }));

    return (
      <Container isPaginationCircleVisible={isPaginationCircleVisible}>
        <GlobalStyle />
        <SwiperContainer className={'simpleSlider'}>
          <NavigationPrevButtonContainer className={navButtonStyle} ref={prevNav} onClick={handlePrevPage}>
            <LeftNavigationIcon />
          </NavigationPrevButtonContainer>

          <NavigationNextButtonContainer className={navButtonStyle} ref={nextNav} onClick={handleNextPage}>
            <RightNavigationIcon />
          </NavigationNextButtonContainer>

          {slides.length > 0 && (
            <StyledSwiper
              className={'swiper'}
              onAfterInit={(swiper: SwiperType) => {
                sliderRef.current = swiper;

                if (prevNav.current && nextNav.current) {
                  swiper.navigation.prevEl = prevNav.current;
                  swiper.navigation.nextEl = nextNav.current;

                  if (isNavigationButtonVisible !== true) {
                    prevNav.current.classList.add('navigationHidden');
                    nextNav.current.classList.add('navigationHidden');
                  }

                  swiper.navigation.update();
                }
              }}
              onBreakpoint={(swiper: SwiperType, breakpointParams: SwiperOptions) => {
                /**
                 * Hack to make navigation.navigationDisabledClass work on init.
                 * As of v11.1.5, the navigationDisabledClass in the breakpoints
                 * does not get applied to the swipe container on page load. It only
                 * gets applied after resizing the window and breakpoint changes.
                 */
                if (nextNav.current && prevNav.current) {
                  const isNavigationEnabled =
                    typeof breakpointParams.navigation === 'boolean'
                      ? breakpointParams.navigation
                      : breakpointParams.navigation?.enabled;

                  if (isNavigationEnabled) {
                    prevNav.current.classList.remove('navigationHidden');
                    nextNav.current.classList.remove('navigationHidden');
                  } else {
                    prevNav.current.classList.add('navigationHidden');
                    nextNav.current.classList.add('navigationHidden');
                  }
                }

                /**
                 * If lower breakpoint has isLooped set to `false` and then window gets resized
                 * to a breakpoint with isLooped set to `true`, then navigation must be refreshed.
                 * Otherwise, the left nav button retains the disabledClass style.
                 */
                swiper.navigation.update();
              }}
              /**
               * As of v11.1.5, loopAddBlankSlides appends unnecessary number of virtual slides
               * when window is resized across breakpoints. As a result, last page is sometimes
               * blank. Keep this option false until the bug is fixed.
               */
              loopAddBlankSlides={false}
              loop={isLooped}
              simulateTouch={true}
              freeMode={false}
              resistanceRatio={0.2}
              spaceBetween={spaceBetweenSlides}
              slidesPerView={slidesPerView}
              slidesPerGroup={slidesPerGroup}
              slidesOffsetBefore={slidesOffsetBefore}
              slidesOffsetAfter={slidesOffsetAfter}
              modules={[Navigation, Pagination, FreeMode]}
              {...(isPaginationCircleVisible && {
                pagination: {
                  enabled: true,
                  clickable: true,
                  bulletClass: 'paginationBullet',
                  bulletActiveClass: 'paginationBulletActive',
                },
              })}
              navigation={{
                enabled: isNavigationButtonVisible,
                prevEl: prevNav.current,
                nextEl: nextNav.current,
                disabledClass: navDisabledButtonStyle,
              }}
              breakpoints={
                breakpoints &&
                Object.fromEntries(
                  Object.entries(breakpoints).map(([key, bp]) => [
                    key,
                    {
                      ...bp,
                      loop: bp.isLooped,
                      spaceBetween: bp.spaceBetweenSlides ? bp.spaceBetweenSlides : 10,
                      pagination: {
                        enabled: bp.isPaginationCircleVisible,
                        clickable: true,
                        bulletClass: 'paginationBullet',
                        bulletActiveClass: 'paginationBulletActive',
                      },
                      navigation: {
                        enabled: bp.isNavigationButtonVisible,
                        prevEl: prevNav.current,
                        nextEl: nextNav.current,
                        disabledClass: navDisabledButtonStyle,
                      },
                    },
                  ])
                )
              }
              onSlidePrevTransitionEnd={() => {
                if (onPrevPageCallback && sliderRef.current) {
                  onPrevPageCallback(sliderRef.current.realIndex);
                }
              }}
              onSlideNextTransitionEnd={() => {
                if (onNextPageCallback && sliderRef.current) {
                  onNextPageCallback(sliderRef.current.realIndex);
                }
              }}
              onPaginationUpdate={(swiper: SwiperType) => {
                if (onPageUpdateCallback) {
                  onPageUpdateCallback(swiper.realIndex);
                }
              }}
            >
              {slides.map(slide => (
                <StyledSwiperSlide>{slide}</StyledSwiperSlide>
              ))}
            </StyledSwiper>
          )}
        </SwiperContainer>
      </Container>
    );
  }
);
